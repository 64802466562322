import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTutors } from "../../hooks";
import { formatMonthsToYear, isMobile } from "../../utils";
import { Tutor } from "../../interfaces";
import queryString from "query-string";
import { ReactComponent as SearchIcon } from "../../svg/search.svg";
import { SwiperPagination } from "../customs/HomeSwiper";
import Swiper from "react-id-swiper";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);

interface TutorCardProps {
  tutor: Tutor;
  backgroundColor: string;
}

function TutorCard({ tutor, backgroundColor }: TutorCardProps) {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  if (isMobile())
    return (
      <div
        style={{
          display: "flex",
          height: 200,
          backgroundColor,
          overflow: "hidden",
          userSelect: "none",
          borderRadius: 15,
          cursor: "pointer",
        }}
        onClick={() => {
          parsed.tutor_detail_id = tutor.id.toString();
          location.search = queryString.stringify(parsed);
          history.replace({ search: location.search });
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            backgroundImage: `url(${tutor.tutor_avatar})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: 140,
            height: "100%",
          }}
        ></div>
        <div
          style={{
            flex: 1,
            padding: 15,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontWeight: 800,
                fontSize: 20,
              }}
            >
              {tutor.tutor_name}
            </div>
            <SearchIcon
              style={{
                width: 20,
                height: 20,
              }}
            />
          </div>
          {tutor.hashtags.map((hashtag, index) => (
            <div
              key={index}
              style={{
                marginTop: 2.5,
                display: "flex",
              }}
            >
              <span
                style={{
                  border: "1px solid rgb(97 97 97)",
                  borderRadius: 5,
                  padding: "2.5px 5px",
                  backgroundColor: "#FFFFFF80",
                  fontSize: 10,
                  display: "inline",
                }}
              >
                #{hashtag}
              </span>
            </div>
          ))}
          <div style={{ marginTop: "auto" }}>
            <div
              style={{
                backgroundColor: "#4A148C",
                borderRadius: 5,
                padding: 5,
                color: "#FFFFFF",
                fontWeight: 600,
                display: "inline-block",
                fontSize: 10,
              }}
            >
              튜터 경력
            </div>
          </div>
          <div style={{ marginTop: 5, fontSize: 12 }}>
            {formatMonthsToYear(tutor.months)} ㅣ {tutor.counts}회 진행
          </div>
        </div>
      </div>
    );

  return (
    <Box
      style={{
        height: "450px",
        backgroundColor,
        display: "flex",
        flexDirection: "column",
        borderRadius: "35px",
        overflow: "hidden",
        userSelect: "none",
        cursor: "pointer",
      }}
      sx={{
        "&:hover": {
          transform: "translateY(-10px)",
        },
        transition: "transform 0.3s ease",
      }}
      onClick={() => {
        parsed.tutor_detail_id = tutor.id.toString();
        location.search = queryString.stringify(parsed);
        history.replace({ search: location.search });
      }}
    >
      <div style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: 800,
              fontSize: 32,
            }}
          >
            {tutor.tutor_name}
          </div>
          <SearchIcon
            style={{
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
            onClick={() => {
              parsed.tutor_detail_id = tutor.id.toString();
              location.search = queryString.stringify(parsed);
              history.replace({ search: location.search });
            }}
          />
        </div>
        <div style={{ marginBottom: -10 }}>
          <div
            style={{
              marginTop: 30,
              backgroundColor: "#4A148C",
              borderRadius: 9,
              padding: 5,
              color: "#FFFFFF",
              fontWeight: 700,
              display: "inline-block",
              fontSize: 14,
            }}
          >
            튜터 경력
          </div>
          <div style={{ marginTop: 10, fontSize: 14 }}>
            {formatMonthsToYear(tutor.months)} ㅣ {tutor.counts}회 진행
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          paddingTop: 30,
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <div style={{ position: "absolute", left: 30, bottom: 30, zIndex: 1 }}>
          <div style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            {tutor.hashtags.map((hashtag, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #292929",
                  borderRadius: 9,
                  padding: 5,
                  backgroundColor: "#FFFFFF80",
                  fontWeight: 400,
                  fontSize: 14,
                }}
              >
                #{hashtag}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            inset: 0,
            left: 35,
            top: 10,
            backgroundImage: `url(${tutor.tutor_avatar})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        ></div>
      </div>
    </Box>
  );
}

function TutorProfile() {
  const { tutors, loaded } = useTutors();
  const [index, setIndex] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (swiperRef) {
      const swiper = (swiperRef as any).swiper;
      swiper.on("slideChange", ({ realIndex }: { realIndex: number }) =>
        setIndex(realIndex)
      );
    }
  }, [swiperRef]);

  if (!loaded) return null;

  const refinedTutors = tutors.filter((tutor) => tutor.status === "active");

  return (
    <div
      style={{
        backgroundColor: "#111111",
      }}
    >
      <div
        style={{
          padding: isMobile() ? 30 : 50,
          maxWidth: isMobile() ? undefined : 1320,
          margin: "0px auto",
        }}
      >
        <div
          className="trirong-extralight"
          style={{
            fontSize: isMobile() ? 35 : 50,
            color: "white",
            textAlign: "center",
          }}
        >
          Meet Our Tutors
        </div>
        <div
          style={{
            marginTop: 15,
            color: "white",
            fontSize: isMobile() ? 16 : 14,
            textAlign: "center",
            marginBottom: isMobile() ? 30 : 100,
          }}
        >
          필로어스 프로그램을 함께 해주실 튜터분들을 소개합니다.
        </div>
        {(() => {
          if (isMobile()) {
            const rows = 4;
            const divided = refinedTutors.length / rows;
            return (
              <>
                <Swiper ref={setSwiperRef} slidesPerView={1} spaceBetween={30}>
                  {(() => {
                    const res = [];
                    for (let i = 0; i < divided; i++) {
                      res.push(
                        <div key={i}>
                          {refinedTutors
                            .slice(rows * i, rows * (i + 1))
                            .map((tutor, tutorIndex) => (
                              <div
                                style={{ marginBottom: 30 }}
                                key={tutorIndex}
                              >
                                <TutorCard
                                  tutor={tutor}
                                  backgroundColor={(() => {
                                    let backgroundColor = "#DAD7FF";
                                    if (
                                      (i % 2 === 0 && tutorIndex % 2 === 1) ||
                                      (i % 2 === 1 && tutorIndex % 2 === 0)
                                    ) {
                                      backgroundColor = "#F5E5FF";
                                    }
                                    return backgroundColor;
                                  })()}
                                />
                              </div>
                            ))}
                        </div>
                      );
                    }
                    return res;
                  })()}
                </Swiper>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SwiperPagination
                    amount={Math.ceil(divided)}
                    ithButtonClicked={(i) =>
                      (swiperRef as any).swiper.slideTo(i)
                    }
                    currentIndex={index + 1}
                  />
                </div>
              </>
            );
          }
          const cols = 4;
          const rows = Math.ceil(refinedTutors.length / cols);
          const res = [];
          for (let i = 0; i < rows; i++) {
            res.push(
              <div
                key={i}
                style={{
                  display: "flex",
                  columnGap: 50,
                  marginBottom: 50,
                }}
              >
                {(() => {
                  const res = [];
                  for (let j = 0; j < cols; j++) {
                    const tutor = refinedTutors[i * cols + j];
                    let backgroundColor = "#DAD7FF";
                    if (
                      (i % 2 === 0 && j % 2 === 1) ||
                      (i % 2 === 1 && j % 2 === 0)
                    ) {
                      backgroundColor = "#F5E5FF";
                    }
                    res.push(
                      <div
                        style={{
                          width: 295,
                        }}
                        key={j}
                      >
                        {tutor && (
                          <TutorCard
                            tutor={tutor}
                            backgroundColor={backgroundColor}
                          />
                        )}
                      </div>
                    );
                  }
                  return res;
                })()}
              </div>
            );
          }
          return res;
        })()}
      </div>
    </div>
  );
}

export default TutorProfile;
